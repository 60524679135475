import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['viewport'];

  static values = { flag: Boolean };

  connect() {
    if (this.flagValue && window.innerWidth < 768) return;

    // Adjust viewport in scaled resolutions for non-mobile devices
    this.viewportTarget.setAttribute('content', `width=device-width, initial-scale=${1 / window.devicePixelRatio}`);
  }
}
