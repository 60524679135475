import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';
import { initModals } from 'flowbite';

export default class extends Controller {
  static targets = ['form', 'patientsTable', 'loadMoreButton'];

  static values = { translations: Object, clinicId: String };

  connect() {
    initModals();
    this.formTarget.addEventListener('ajax:success', this.handleAjaxSuccess.bind(this));
  }

  handleAjaxSuccess(event) {
    const data = event.detail[0];

    this.patientsTableTarget.querySelector('table > tbody').innerHTML = this.buildRows(data);

    this.prepareLoadMoreButton(data);
  }

  loadMore(event) {
    event.preventDefault();
    this.loadMoreButtonTarget.disabled = true;

    Rails.ajax({
      url: this.loadMoreButtonTarget.dataset.url,
      type: 'GET',
      dataType: 'json',
      success: this.handleLoadMoreSuccess.bind(this),
    });
  }

  handleLoadMoreSuccess(data) {
    this.patientsTableTarget.querySelector('table > tbody').insertAdjacentHTML('beforeend', this.buildRows(data));

    this.loadMoreButtonTarget.disabled = false;

    this.prepareLoadMoreButton(data);
  }

  buildRows(dataResponse) {
    const { data } = dataResponse;

    if (data.length === 0) return this.emptyRow();

    return data.map((row) => `
        <tr>
          <td><a href="${row.attributes.url}&clinic_id=${this.clinicIdValue}" target="_blank" class="text-blue-500">${row.attributes.name}</a></td>
          <td>${row.attributes.date_of_birth}</td>
          <td>${row.attributes.age}</td>
          <td>${row.attributes.sex}</td>
        </tr>
      `).join('');
  }

  emptyRow() {
    return `
      <tr colspan="4" class="text-center">
        <td colspan="4">${this.translationsValue.no_patients}</td>
      </tr>
    `;
  }

  prepareLoadMoreButton(dataResponse) {
    const { next } = dataResponse.links[0];

    if (next) {
      this.loadMoreButtonTarget.classList.remove('!hidden');
      this.loadMoreButtonTarget.dataset.url = next;
    } else {
      this.loadMoreButtonTarget.classList.add('!hidden');
    }
  }

  resetForm(event) {
    event.preventDefault();
    this.formTarget.reset();
    this.clearTable();
  }

  clearTable() {
    this.patientsTableTarget.querySelector('table > tbody').innerHTML = this.emptyRow();
    this.loadMoreButtonTarget.classList.add('!hidden');
  }

  clearDateOfBirth(event) {
    event.preventDefault();
    this.formTarget.querySelector('#search_date_of_birth_2i').value = '';
    this.formTarget.querySelector('#search_date_of_birth_3i').value = '';
    this.formTarget.querySelector('#search_date_of_birth_1i').value = '';
  }
}
