import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['ageRangeDisabled', 'ageRangeEnabled', 'minAge', 'maxAge'];

  updateRequiredFields() {
    if (this.ageRangeDisabledTarget.checked) {
      this.minAgeTarget.required = '';
      this.maxAgeTarget.required = '';
    } else {
      this.minAgeTarget.required = 'required';
      this.maxAgeTarget.required = 'required';
    }
  }
}
